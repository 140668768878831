<template>
	<LoginLayout
		form-title="Account locked out"
		portal-name="Customer Service Portal"
	>
		<template #form>
			<Message>
				Your account is locked out
			</Message>
			<a @click="$router.push({ path: '/forgotten-password' })">
				Please click here to reset your password.
			</a>
		</template>
	</LoginLayout>
</template>
<script>
import LoginLayout from '../../../../../shared/layouts/login-layout.vue'
import Message from '../../../../../shared/components/message.vue'
export default {
	components: { Message, LoginLayout }
}
</script>
